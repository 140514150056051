import { Button, Flex, Grid, notify, Select } from 'front-commons/ds';
import { useFetch } from 'front-commons/hooks';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { changeProductDistributor } from 'services/basket';
import { BusinessUnitProps } from 'services/basket/interfaces';
import { getProductsDetails } from 'services/products';
import { ProductDetails, UnavailableProductDetailsParams } from 'services/products/interfaces';
import useBasket from 'stores/basket';
import useDrawer from 'stores/drawer';
import usePos from 'stores/pos';
import { DistributorChangeProps } from './interfaces';
import { Typography } from 'hype-ds';
import ProductCard from './components/ProductCard';
import CartTotals from '../SideBasketDrawer/components/CartTotals';

type NotArrayOf<T> = T extends Array<infer U> ? U : T;

export default function DistributorChange({
	products,
	sourceId,
	sourceType,
	sourceName,
	distributorId: initialDistributorId,
	distributorName,
}: DistributorChangeProps) {
	const {
		handleGetBasketData,
		handleRemoveFromBasket,
		basketStore: { deleting },
	} = useBasket();

	const {
		posStore: {
			selectedPos: { id: posId },
		},
	} = usePos();

	const { handleCloseAllDrawers } = useDrawer();

	const [productData, setProductData] = useState<ProductDetails[]>([]);
	const [emptyState, setEmptyState] = useState(false);
	const [massiveValue, setMassiveValue] = useState('');
	const [changedProducts, setChangedProducts] = useState(
		{} as Record<ObjectID, NotArrayOf<BusinessUnitProps['products']>>,
	);

	const commonTotalsProps = {
		element: Typography.Paragraph.S,
		props: {
			color: '--text-primary',
		},
	};

	const massiveDistributorsData = useMemo(() => {
		let data = {} as Record<ObjectID, GenericLabelValue>;

		const productsDistributors = productData.map(({ distributors }) =>
			distributors.filter(({ status }) => status !== 'UNAVAILABLE'),
		);

		productData.forEach((product) => {
			product.distributors.forEach((distributor) => {
				const hasIsCommon = productsDistributors
					.map(
						(productDistributors) =>
							!!productDistributors.find(({ distributorId }) => distributor.distributorId === distributorId),
					)
					.every(Boolean);

				if (!data?.[distributor.distributorId] && distributor.distributorId !== initialDistributorId && hasIsCommon) {
					data = {
						...data,
						[distributor.distributorId]: {
							label: distributor.distributorName.capitalize({ rule: 'tradeName' }),
							value: distributor.distributorId,
						},
					};
				}
			});
		});

		return data;
	}, [productData]);

	const totals = useMemo(() => {
		return productData.reduce(
			(acc, { distributors, id }) => {
				const distributorId = changedProducts[id]?.distributorId || initialDistributorId;
				const curr = distributors.find((item) => item.distributorId === distributorId)!;

				// @ts-ignore
				const quantity = Math.round((curr.finalTotalPrice || 1) / (curr.finalUnitPrice || 1));
				const brute = (curr.unitPrice || 0) * quantity;
				const discount = ((curr.discount || 0) / 100) * brute;
				const tax = (curr.taxSubstitution || 0) * quantity;

				return {
					discount: discount + acc.discount,
					brute: brute + acc.brute,
					tax: tax + acc.tax,
				};
			},
			{ discount: 0, brute: 0, tax: 0 },
		);
	}, [productData, changedProducts]);

	const { loading: productDataLoading } = useFetch({
		fetchFunction: getProductsDetails,
		fetchParams: {
			posId,
			products: products.map((product) => {
				const param: UnavailableProductDetailsParams = {
					productId: product.id,
					quantity: product.quantity,
				};

				if (product.productsInCombo.length) param.productsInCombo = product.productsInCombo;

				return param;
			}),
		},
		onSuccess(response) {
			if (!response?.products) return;

			const productsDetails = response.products.filter((item) =>
				item.distributors.some((distributor) => distributor.status !== 'UNAVAILABLE'),
			);

			if (productsDetails.length === 0) setEmptyState(true);

			setProductData(productsDetails);
		},
		fetchOnRender: true,
	});

	const handleRemoveFromStates = useCallback((productId: ObjectID | ObjectID[]) => {
		const subjects = Array.isArray(productId) ? productId : [productId];

		setProductData((prevState) => prevState.filter(({ id }) => !subjects.includes(id)));
		setChangedProducts((prevState) => {
			const newState = { ...prevState };

			subjects.forEach((subject) => {
				delete newState[subject];
			});

			return newState;
		});
	}, []);

	const { fetch: handleApplyChanges, loading: applyLoading } = useFetch({
		fetchFunction: changeProductDistributor,
		fetchParams: {
			posId,
			data: {
				[`${sourceType}Id`]: sourceId,
				products: Object.values(changedProducts),
			},
		},
		onSuccess() {
			const withoutOptions = productData.length === 1 || Object.keys(changedProducts).length === productData.length;

			if (withoutOptions) handleCloseAllDrawers();
			if (!withoutOptions) handleRemoveFromStates(Object.keys(changedProducts));

			handleGetBasketData({ loading: 'refetch', initializeBefore: true });
			notify.positive({ description: 'Distribuidores alterados com sucesso.' });
		},
		onError() {
			notify.negative({ description: 'Falha ao alterar distribuidores.' });
		},
	});

	const handleMassiveChange = useCallback(
		({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => {
			setMassiveValue(value);

			setChangedProducts(
				productData.reduce((acc, { id }) => {
					const { quantity, productsInCombo } = products.find((item) => item.id === id)!;

					const data: NotArrayOf<BusinessUnitProps['products']> = {
						quantity,
						productId: id,
						distributorId: value,
					};

					if (productsInCombo?.length) {
						data.productsInCombo = productsInCombo.map((item) => ({
							productId: item.productId,
							quantity: item.quantity,
						}));
					}

					return {
						...acc,
						[id]: data,
					};
				}, {}),
			);
		},
		[products, productData],
	);

	const handleSingleChange = useCallback(
		(productId: ObjectID, distributorId: ObjectID) => {
			setMassiveValue('');

			setChangedProducts((prevState) => {
				if (distributorId === initialDistributorId) {
					const { [productId]: _, ...rest } = prevState;

					return rest;
				}

				if (prevState[productId]) {
					prevState[productId].distributorId = distributorId;

					return { ...prevState };
				}

				const { quantity, productsInCombo } = products.find(({ id }) => id === productId)!;

				const data: NotArrayOf<BusinessUnitProps['products']> = {
					quantity,
					productId,
					distributorId,
				};

				if (productsInCombo?.length) {
					data.productsInCombo = productsInCombo.map((item) => ({
						productId: item.productId,
						quantity: item.quantity,
					}));
				}

				return {
					...prevState,
					[productId]: data,
				};
			});
		},
		[products],
	);

	const handleRemoveProduct = useCallback(
		async (productId: ObjectID) => {
			try {
				await handleRemoveFromBasket(productId);

				if (productData.length === 1) return handleCloseAllDrawers();

				handleRemoveFromStates(productId);
			} catch {
				notify.negative({ description: 'Falha ao remover produto.' });
			}
		},
		[productData],
	);

	return (
		<Flex direction="column" gap="0px" height="100%" maxHeight="100%">
			<Flex gap="16px" padding="16px 16px 0" direction="column" as="section" data-testid="Content">
				<Flex justifyContent="space-between" as="header" data-testid="Header">
					<Flex gap="8px" alignItems="center" data-testid="Header-Title">
						<Typography.Heading.XXS color="--text-primary">Alterar distribuidor</Typography.Heading.XXS>
						<Typography.Paragraph.XS color="--text-primary">
							{productData.length || products.length} ite{(productData.length || products.length) > 1 ? 'ns' : 'm'}
						</Typography.Paragraph.XS>
					</Flex>

					<Button
						variant="none"
						onClick={handleCloseAllDrawers}
						leftIcon={{ name: 'close', size: '24px', color: '--text-link' }}
						data-testid="Header-CloseButton"
					/>
				</Flex>

				<Flex
					gap="8px"
					margin="0 8px 16px"
					alignItems="center"
					border="1px solid"
					borderWidth="0 0 1px"
					borderColor="--border-primary"
					data-testid="Title"
				>
					<Typography.Heading.XS color="--text-primary">{sourceName}</Typography.Heading.XS>
					<Typography.Heading.XXS color="--text-secondary">
						{distributorName.capitalize({ rule: 'tradeName' })}
					</Typography.Heading.XXS>
				</Flex>

				{(productData.length || products.length) > 1 && !emptyState && (
					<Flex
						gap="16px"
						width="100%"
						margin="-8px 0 16px"
						padding={{ small: '0 49px 0 8px', medium: '0 8px' }}
						direction={{ small: 'column', medium: 'row' }}
						alignItems={{ small: 'flex-start', medium: 'center' }}
						as={Grid}
						columns={{ small: '1fr', medium: '216px 228px' }}
					>
						<Typography.Paragraph.S color="--text-primary">
							Alterar todos os distribuidores para:
						</Typography.Paragraph.S>

						<Select
							size="small"
							name="massiveDistributorSelect"
							value={massiveValue}
							options={Object.values(massiveDistributorsData)}
							onChange={handleMassiveChange}
							loading={productDataLoading}
							disabled={!Object.values(massiveDistributorsData).length}
							placeholder={
								!Object.values(massiveDistributorsData).length && !productDataLoading
									? 'Sem opções'
									: 'Selecione um distribuidor'
							}
							permitClearSelected={false}
						/>
					</Flex>
				)}
			</Flex>
			<Flex direction="column" padding="0 24px 24px" gap="24px" height="100%" overflow="auto">
				{productDataLoading && <ProductCard.Skeleton quantity={products.length} />}

				{emptyState && (
					<Flex gap="16px" padding="16px" direction="column" alignItems="center" margin="56px 0 0">
						<Typography.Paragraph.M color="--text-primary">Nenhum produto disponível.</Typography.Paragraph.M>
					</Flex>
				)}

				{!emptyState &&
					productData.map((product) => {
						const hyperaCode = product.productsInCombo
							? products.find(({ id }) => id === product.id)?.hyperaCode
							: undefined;

						return (
							<ProductCard
								key={product.id}
								id={product.id}
								name={product.description}
								ean13={product.ean13}
								deleting={deleting}
								disabled={productDataLoading || applyLoading}
								onRemove={handleRemoveProduct}
								hyperaCode={hyperaCode}
								distributors={product.distributors}
								onDistributorSelect={handleSingleChange}
								initialDistributorId={initialDistributorId}
								selectedDistributorId={changedProducts?.[product.id]?.distributorId}
							/>
						);
					})}
			</Flex>
			{!productDataLoading && !emptyState && (
				<Flex
					gap="24px"
					width="100%"
					height="fit-content"
					padding="24px"
					direction="column"
					boxShadow="0px -4px 24px 0px #00000029"
					alignItems="flex-end"
					as="footer"
				>
					<CartTotals
						liquid={totals.brute - totals.discount + totals.tax}
						{...totals}
						TypographyOverwrite={{
							tax: commonTotalsProps,
							brute: commonTotalsProps,
							discount: commonTotalsProps,
							totalLiquid: commonTotalsProps,
							total: {
								element: {
									label: Typography.Paragraph.S,
									value: Typography.Label.L,
								},
								props: {
									color: '--text-primary',
								},
							},
						}}
					/>

					<Button
						size="large"
						loading={applyLoading}
						onClick={() => handleApplyChanges()}
						disabled={productDataLoading}
					>
						Alterar distribuidores
					</Button>
				</Flex>
			)}
		</Flex>
	);
}

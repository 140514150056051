import { useSelector, useDispatch } from 'react-redux';
import { Redux } from 'stores/interfaces';
import * as middleware from './middleware';
import {
	CustomerAccess,
	CustomerProvider,
	CustomerRole,
	HandleGetCustomerDataReturn,
	PermissionGateProps,
	PermissionIds,
} from './interfaces';
import { CustomerDataResponse } from 'services/customer/interfaces';
import { useLocation, useNavigate } from 'react-router-dom';
import { customerPermissions } from './helpers';
import { useEffect } from 'react';
import useElasticApm from 'contexts/ApmElastic';

export default function useCustomer() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { state } = useLocation();

	const customerStore = useSelector((stores: Redux) => stores.customerReducer);
	const posStore = useSelector((stores: Redux) => stores.posReducer);

	const { setAPMUser } = useElasticApm();

	const handleGoToInitialPage = () => {
		if (customerStore.data.accessType) return navigate('/simular-farmacia', { state });
		if (!posStore.myPos.length) return navigate('/primeiro-acesso', { state });
		return navigate('/minhas-farmacias', { state });
	};

	const handleManageFirstAccess = {
		get(posId: string) {
			return customerStore.firstAccess?.includes(posId);
		},
		remove(posId: string) {
			dispatch(middleware.handleManageFirstAccess('REMOVE', posId));
		},
		add(posId: string) {
			dispatch(middleware.handleManageFirstAccess('ADD', posId));
		},
		clear(posId: string) {
			dispatch(middleware.handleManageFirstAccess('CLEAR', posId));
		},
	};

	// SIMULATED HANDLERS

	const handleHasPermission = (id: PermissionIds) => {
		const { accessType, hyperaRole } = customerStore.data;

		if (accessType === CustomerAccess.BASIC || hyperaRole === CustomerRole.CONSULTANT) return false;
		if (accessType === CustomerAccess.ACTION) return customerPermissions.simulated.action.includes(id as any);

		return true;
	};

	const simulatedViewType = () => {
		const { accessType } = customerStore.data;
		if (accessType) return accessType;

		return false;
	};

	const PermissionGate = ({ children, id }: PermissionGateProps) => (handleHasPermission(id) ? children : null);
	const mainCustomerPage = () => (simulatedViewType() ? '/simular-farmacia' : '/minhas-farmacias');

	useEffect(() => {
		setAPMUser({ id: customerStore.data.id, email: customerStore.data.email, name: customerStore.data.name });
	}, [customerStore.data.id]);

	return {
		customerStore,
		handleManageFirstAccess,

		handleSetLoading: (loading: boolean) => dispatch(middleware.handleSetLoading(loading)),
		handleResetUserIsLoggingOut: () => dispatch(middleware.handleResetUserIsLoggingOut()),
		handleGetCustomerData: (): Promise<HandleGetCustomerDataReturn> => dispatch(middleware.handleGetCustomerData()),
		handleSetLogout: (userIsLoggingOut?: boolean) => dispatch(middleware.handleSetLogout(userIsLoggingOut)),
		handleSetToken: (token: string, provider: CustomerProvider = 'PARCEIRO_HYPERA') => {
			dispatch(middleware.handleSetToken(token));
			dispatch(middleware.handleSetProvider(provider));
		},
		handleSetProvider: (provider: CustomerProvider) => dispatch(middleware.handleSetProvider(provider)),
		handleUpdatePartially: (customerData: Partial<CustomerDataResponse>) =>
			dispatch(middleware.handleUpdatePartially(customerData)),
		handleUpdate2FAExpiration: (timestamp: number) => dispatch(middleware.handleUpdate2FAExpiration(timestamp)),
		handleGoToInitialPage,
		handleHasPermission,
		PermissionGate,
		simulatedViewType,
		mainCustomerPage,
	};
}

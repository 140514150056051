import { AxiosError } from 'axios';
import { createAPIConnection } from 'services';
import { ResponseParams } from 'services/interfaces';
import { simulatedViewType } from 'stores/customer/helpers';
import { getStore } from 'stores/helpers';
import { getSimulatedPosListManage } from './helpers';
import {
	DataValidationResponse,
	FindPharmacyRequest,
	FindPharmacyResponse,
	QueryParams,
	FindMyPharmaciesResponse,
	FindPharmaciesResponse,
	SolicitationListQueryParams,
	PharmaciesListAccessResponse,
	SolicitationListResponse,
	BannersResponse,
	SimulatedPosSuggestionsResponse,
	PreValidationComponentProps,
	PreValidationRequest,
	SupervisorChangeResponse,
	SelectedFlagResponse,
	FindPharmaciesRequest,
	PosLinkPendingRequest,
	PosLinkPendingResponse,
	PosLinkValidateRequest,
	PosLinkValidateResponse,
	PosLinkGenerateRequest,
	PosLinkGenerateResponse,
	PosLinkStatusResponse,
	PharmacyDistributorsCheckRequest,
} from './interfaces';
import { requestHandler } from '../helpers';

export * from './distributors'

export const api = createAPIConnection('', {
	onRequest(config) {
		const provider = getStore().customerReducer?.provider;

		if (provider === 'ZECA_COPILOTO') Object.assign(config?.headers || {}, { 'Partner-origin': 'zecacopiloto' });
	},

	onReject(error) {
		if (error.config.url === '/pos/pre-validation/customer' && error.response.status !== 401) throw error.response;
	},
});

export const findPharmacy = async (params: FindPharmacyRequest) => {
	return requestHandler<FindPharmacyResponse>(api.get('/pos/detail', { params }));
};

export const validatePharmacyCnpj = async (params: QueryParams) => {
	return requestHandler<DataValidationResponse>(api.get('/pos/validate', { params }));
};

export const findMyPharmaciesNoHandler = async (params?: GenericObject) => {
	const response = await api.get<FindMyPharmaciesResponse>('/pos/list/customer', { params });

	return response?.data;
};

export const searchPosData = async ({ forcePosList, ...params }: FindPharmaciesRequest = { summarized: true }) => {
	if (simulatedViewType() && !forcePosList) {
		const simulatedPos = await getSimulatedPosListManage();

		return simulatedPos;
	}

	try {
		const data = await requestHandler<FindPharmaciesResponse[]>(api.post('/pos/list', params), { throwData: true });
		return data;
	} catch (error) {
		return []
	}

};

export const pharmacyOwnerSolicitationListAccess = async (posId: string, params: SolicitationListQueryParams) => {
	const { status } = params;
	return requestHandler<SolicitationListResponse>(
		api.get(`/pos/management/access/${posId}`, { params: { ...params, status: status?.join(',') } }),
	);
};

export const pharmacyDistributorsCheck = async (pharmacyId: string) => {
	return requestHandler<PharmacyDistributorsCheckRequest>(
		api.get(`/pos/${pharmacyId}/distributors/check`, {
			headers: { 'X-Marketplace': true },
		}),
		{ throwData: true }
	);
};

export const posLinks = async (posId: string, params: SolicitationListQueryParams) => {
	const { status } = params;
	return requestHandler<SolicitationListResponse>(
		api.get(`/pos/management/access/${posId}`, { params: { ...params, status: status?.join(',') } }),
	);
};

export const posLinkAccessManage = (pointOfSaleCustomerIdList: string[], type: 'accept' | 'reject' | 'block') => requestHandler<ResponseParams>(
	api.post(`/pos/management/access/${type}`, { pointOfSaleCustomerIdList })
);

export const posLinkPending = async (payload: PosLinkPendingRequest) => requestHandler<PosLinkPendingResponse>(
	api.post(`pos/management/access/pending`, payload)
);

export const pharmaciesListOwnerAccess = async () => {
	if (simulatedViewType()) {
		const simulatedPos = await getSimulatedPosListManage();
		return simulatedPos;
	}

	return requestHandler<PharmaciesListAccessResponse[]>(api.get('/pos/management/access'));
};

export const getDataBanners = async (posId: string, tag: string) => {
	return requestHandler<BannersResponse[]>(api.get('/pos/banners', { params: { posId, tag } }));
};

export const getUserSimulatedPosSuggestions = async ({ cnpj }: { cnpj: string }) => {
	try {
		const response = await api.get(`/pos/list/${cnpj}`);

		return response.data.content as SimulatedPosSuggestionsResponse[];
	} catch (error) {
		if (error instanceof AxiosError) {
			throw error?.response?.data?.status;
		}

		throw error;
	}
};

export const preValidationCustomer = async (posData: PreValidationRequest) => {
	return requestHandler<PreValidationComponentProps>(api.post('/pos/pre-validation/customer', posData), {
		throwData: true,
	});
};

export const posSupervisorChange = async () => {
	return requestHandler<SupervisorChangeResponse>(api.get('/pos/supervisor-change'));
};

export const getFlags = async (params?: GenericObject) => {
	return requestHandler<SelectedFlagResponse>(api.get('/pos/commercial-flag/list', { params }));
};

export const posLinkValidate = async (data: PosLinkValidateRequest) =>
	requestHandler<PosLinkValidateResponse>(api.post('/pos/management/access/request/validate', data),
		{ throwData: true }
	);

export const posLinkGenerate = async (data: PosLinkGenerateRequest) =>
	requestHandler<PosLinkGenerateResponse>(api.post('/pos/management/access/request/generate', data),
		{ throwData: true }
	);

export const posLinkStatus = async ({ accessRequestId }: { accessRequestId: ObjectID }) =>
	requestHandler<PosLinkStatusResponse>(api.get(
		`/pos/management/access/request/${accessRequestId}/status`),
		{ throwData: true }
	);
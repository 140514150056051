import { useSelector, useDispatch } from 'react-redux';
import { Redux } from 'stores/interfaces';
import * as middleware from './middleware';
import { FidelityReducer, FidelityStatus, HandleFidelityParams, HandleRetryFetchDataParams } from './interfaces';
import { useCallback, useMemo } from 'react';
import { RoleTypes } from 'stores/pos/interfaces';
import usePos from 'stores/pos';
import dayjs from 'dayjs';
import { useLocation } from 'react-router';
import { notify } from 'front-commons/ds';

export default function useFidelity() {
	const dispatch = useDispatch();
	const fidelityStore = useSelector((stores: Redux) => stores.fidelityReducer);

	const {
		posStore: { selectedPos },
	} = usePos();

	const location = useLocation();

	const handleSetToken = (posId: string, onlyValidate: boolean) => dispatch(middleware.handleSetToken(posId, onlyValidate));

	const navigateToFidelityPage = useCallback(async () => {
		try {
			dispatch(middleware.handleLoadingToken(true));
			const token = await handleSetToken(selectedPos.id, false);
			window.open(`${import.meta.env.VITE_BONI_REDIRECT}?token=${token}`);
		} catch {
			notify.negative({
				description: 'Não foi possível navegar para a página da fidelidade! Por favor, tente novamente.',
			});
		} finally {
			dispatch(middleware.handleLoadingToken(false));
		}
	}, [fidelityStore.tokenFidelity]);

	const isRetryFetchDataExpired = useMemo(() => {
		if (!fidelityStore.retryFetchData) return false;

		const msToExpire = dayjs(fidelityStore.retryFetchData).diff(dayjs().format(), 'ms');

		return msToExpire <= 0;
	}, [fidelityStore.retryFetchData, location.pathname]);

	return {
		fidelityStore,
		isRetryFetchDataExpired,
		navigateToFidelityPage,
		handleSetToken,
		handleLoading: (state: FidelityReducer['loading']) => dispatch(middleware.handleLoading(state)),
		handleSetIsOwner: (role?: RoleTypes) => dispatch(middleware.handleSetIsOwner(role)),
		handleSetStatus: (status: FidelityStatus) => dispatch(middleware.handleSetStatus(status)),
		handleSetFidelityData: (fidelity: HandleFidelityParams) => dispatch(middleware.handleSetFidelityData(fidelity)),
		handleRetryFetchData: (state: HandleRetryFetchDataParams) => dispatch(middleware.handleRetryFetchData(state)),
		handleClearRetryFetchData: () => dispatch(middleware.handleClearRetryFetchData()),
		handleClearFidelity: () => dispatch(middleware.handleClearFidelity()),
	};
}

export const formatProducts = (products: any, additionalItems?: boolean) => {
	return products.flat().reduce((acc: any, product: any) => {
		if (product.productType === 'COMBO') {
			product.productsInCombo.forEach((p: any) => {
				const GAChildProduct: Record<string, unknown> = {
					item_id: p.ean13,
					item_name: p.description,
					item_variant: product?.businessUnitName || product?.industryName! || '',
					price: p.unitPricePromotion,
					quantity: p.quantity,
					discount: p.unitDiscount,
					affiliation: product.affiliation,
					promotion_id: product.hyperaCode || '',
					promotion_name: product.description,
					...(additionalItems ? { minimum_value: product.minimum_value } : {}),
				};

				if (p.suggestionGroupName) GAChildProduct.item_list_name = p.suggestionGroupName

				acc.push(GAChildProduct);
			});

			return acc;
		}

		const GAProduct: Record<string, unknown> = {
			item_id: product.ean13,
			item_name: product.description,
			item_variant: product?.businessUnitName || product?.industryName!,
			price: product.unitPricePromotion,
			quantity: product.quantity,
			discount: product.unitDiscount,
			affiliation: product.affiliation,
			...(additionalItems ? { minimum_value: product.minimum_value } : {}),
		}

		if (product.suggestionGroupName) GAProduct.item_list_name = product.suggestionGroupName

		acc.push(GAProduct);

		return acc;
	}, []);
};

export const handlePushEvent = (event: any) => {
	try {
		if (!event.event) throw new Error('Event name is required');
		if (!window.dataLayer) window.dataLayer = [];

		window.dataLayer.push({ ecommerce: null });
		window.dataLayer.push(event);
	} catch (error) {
		console.error('Error on push event', error);
	}
}
import { Flex, Skeleton, Typography } from 'front-commons/ds';
import { DistributorCardProps } from '../../interfaces';
import SourceCard from '../SourceCard';
import { memo } from 'react';

export default function DistributorCard({
	openIndex,
	name,
	posId,
	sources,
	loadingBasketType,
	deletingProductId,
	hasEditBasketPermission,
	handleOpenDrawer,
	handleRemoveFromBasket,
}: DistributorCardProps) {
	return (
		<Flex
			as="section"
			gap="0px"
			border="1px solid"
			noShrink
			overflow="hidden"
			direction="column"
			borderColor="--border-primary"
			data-testid="side-basket-Distributor-card-Container"
			borderRadius="8px"
		>
			<Flex
				as="header"
				width="100%"
				padding="8px 16px"
				data-testid="side-basket-Distributor-card-Header"
				backgroundColor="--surface-primary"
			>
				<Typography variant="Paragraph/Semibold">{name.capitalize({ rule: 'tradeName' })}</Typography>
			</Flex>
			<Flex
				as="article"
				gap="1px"
				direction="column"
				data-testid="side-basket-Distributor-card-Content"
				backgroundColor="--border-primary"
			>
				{sources.map((source) => (
					<SourceCard
						key={source.id}
						data={source}
						posId={posId}
						loadingBasketType={loadingBasketType}
						deletingProductId={deletingProductId}
						hasEditBasketPermission={hasEditBasketPermission}
						handleOpenDrawer={handleOpenDrawer}
						handleRemoveFromBasket={handleRemoveFromBasket}
						openByDefault={openIndex === 0}
					/>
				))}
			</Flex>
		</Flex>
	);
}

DistributorCard.Skeleton = memo(() => (
	<Flex
		gap="0px"
		width="100%"
		border="1px solid"
		noShrink
		overflow="hidden"
		direction="column"
		borderColor="--border-primary"
		borderRadius="8px"
		data-testid="side-basket-Distributor-card-Skeleton"
	>
		<Flex width="100%" padding="8px 16px" height="40px" backgroundColor="--surface-primary" alignItems="center">
			<Skeleton height="24px" width="100px" />
		</Flex>

		<Flex padding="16px 16px 24px" height="82px">
			<Flex justifyContent="space-between" gap="14px" width="100%" alignItems="center">
				<Flex direction="column" width="100%" gap="4px">
					<Flex justifyContent="space-between" alignItems="center">
						<Skeleton height="14px" width="100px" />
						<Skeleton height="12px" width="100px" />
					</Flex>

					<Skeleton height="1px" width="100%" />
				</Flex>

				<Skeleton width="16px" height="16px" />
			</Flex>
		</Flex>
	</Flex>
));

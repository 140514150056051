import { Button, Flex, Header, Typography } from 'front-commons/ds';
import { theme } from 'front-commons/ds/core/tokens';
import { useMediaQuery } from 'front-commons/hooks';
import { useNavigate } from 'react-router-dom';
import ContactUs from 'containers/ContactUs';
import Head from 'routes/Head';
import { findCategories } from 'services/category';
import { getSearchPreview } from 'services/products';
import { searchEventGTM, selectContentGTM } from 'shared/gtm';
import usePosSearch from 'shared/pos/usePosSearch';
import useBasket from 'stores/basket';
import useCatalog from 'stores/catalog';
import useCustomer from 'stores/customer';
import useFidelity from 'stores/fidelity';
import usePos from 'stores/pos';
import { ErrorPageProps } from './interface';
import { ErrorContainer, ErrorImageWrapper } from './styles';
import { getUserMenuItems } from 'routes/helpers';
import { roleParser } from 'containers/ManagePersons/helpers';

export default function Error({
	isGlobalBoundary,
	errorType,
	subtitle,
	description,
	showReload,
	showButtonGoToPH = false,
	showHeader = true,
}: ErrorPageProps) {
	const {
		basketStore: { basket, loading: loadingBasket },
		handleOpenBasket,
	} = useBasket();
	const {
		catalogStore: { catalogMenuItems },
	} = useCatalog();
	const { customerStore, handleSetLogout, mainCustomerPage, simulatedViewType } = useCustomer();
	const {
		fidelityStore,
		handleClearRetryFetchData,
		handleRetryFetchData,
		handleSetFidelityData,
		isRetryFetchDataExpired,
		navigateToFidelityPage,
	} = useFidelity();
	const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.medium})`);
	const navigate = useNavigate();
	const { posStore, handleSelectPos, selectedPos } = usePos();

	const imdSrc = isTablet
		? `https://hyperaadminprod.blob.core.windows.net/site-images/luana-erro-${errorType || '404'}.png`
		: 'https://hyperaadminprod.blob.core.windows.net/site-images/luana-erro-404_mobile.png'; // é a mesma imagem para 500

	const headTitle = {
		404: 'Página não encontrada',
		500: 'Serviço indisponível',
	};

	const userMenuItems = getUserMenuItems(posStore.selectedPos);

	return (
		<>
			{errorType && <Head title={headTitle[errorType]} />}

			{showHeader && (
				<Header
					basketData={basket}
					catalogMenuItems={catalogMenuItems}
					customerStore={customerStore}
					fidelityStore={fidelityStore}
					findCategories={findCategories}
					getSearchPreview={getSearchPreview}
					getUsePosSearch={usePosSearch}
					handleClearRetryFetchData={handleClearRetryFetchData}
					handleOpenBasket={handleOpenBasket}
					handleRetryFetchData={handleRetryFetchData}
					handleSelectPos={handleSelectPos}
					handleSetFidelityData={handleSetFidelityData}
					imageProvider={import.meta.env.VITE_STATIC_STORAGE_BASE}
					isRetryFetchDataExpired={isRetryFetchDataExpired}
					isSuggestionsProductNotifierExpired
					loadingBasket={loadingBasket}
					loadingPos={posStore.loading}
					mainCustomerPage={mainCustomerPage}
					myPos={posStore.myPos}
					navigateToFidelityPage={navigateToFidelityPage}
					posStore={posStore}
					searchEventGTM={searchEventGTM}
					selectContentGTM={selectContentGTM}
					selectedPos={selectedPos}
					simulatedViewType={simulatedViewType}
					type={isGlobalBoundary ? 'logged_and_not_logged' : 'dynamic'}
					logoNavigateReload
					userMenu={{
						data:
							posStore.selectedPos?.role === 'SUPERVISOR'
								? userMenuItems.filter((item: any) => item.text !== 'Permissões de acesso')
								: userMenuItems,
						onLogout: () => handleSetLogout(true),
						userName: customerStore.data.name,
						userRole: posStore.selectedPos?.role && roleParser(posStore.selectedPos?.role),
						linkToSimulated: !!simulatedViewType() ? '/simular-farmacia' : undefined,
					}}
				/>
			)}

			<ErrorContainer>
				<ErrorImageWrapper>
					<img
						src={imdSrc}
						alt="Imagem da Luana, com camiseta branca, óculos com a armação vermelha, com a mão sobre o rosto e um semblante de assustada."
					/>
				</ErrorImageWrapper>

				<div>
					<Flex
						direction="column"
						gap="0px"
						margin={{ small: '0 0 32px 0', medium: '0 0 24px 0', large: '16px 0 0 0' }}
					>
						{errorType ? (
							<Typography
								as="h1"
								variant={{ small: 'Headline/H2 Bold', medium: 'Headline/H1 Bold' }}
								color="--text-link"
								style={{ fontWeight: 400 }}
							>
								Erro {errorType}
							</Typography>
						) : (
							<Typography
								as="h1"
								variant={{ small: 'Headline/H2 Bold', medium: 'Headline/H1 Bold' }}
								color="--text-link"
								style={{ fontWeight: 400 }}
							>
								Página não encontrada
							</Typography>
						)}

						{subtitle && (
							<Typography as="h2" variant={{ small: 'Paragraph/Semibold', medium: 'Subtitle' }} color="--text-primary">
								{subtitle}
							</Typography>
						)}
					</Flex>

					<Flex direction="column" maxWidth={{ medium: '624px' }} gap={{ small: '90px', medium: '24px' }}>
						<div>
							<Flex direction="column" margin={{ small: '0 32px 16px 0', medium: '0 0 24px 0' }}>
								<Typography color="--text-primary">{description}</Typography>
							</Flex>

							<ContactUs phoneUnderline phoneColor="--text-link" />
						</div>

						{showReload && (
							<Flex direction="column" gap="16px" alignItems={{ small: 'center', medium: 'flex-start' }}>
								<Typography color="--text-primary">Ou recarregue essa página</Typography>

								<Button
									size="large"
									onClick={() => navigate(0)}
									width="100%"
									maxWidth={{ small: '260px', medium: '280px' }}
								>
									Tente novamente
								</Button>
							</Flex>
						)}

						{showButtonGoToPH && (
							<Flex direction="column" alignItems={{ small: 'center', medium: 'flex-start' }}>
								<Button
									size="small"
									onClick={() => navigate('/')}
									width="100%"
									maxWidth={{ small: '260px', medium: '280px' }}
								>
									Ir para o Parceiro Hypera
								</Button>
							</Flex>
						)}
					</Flex>
				</div>
			</ErrorContainer>
		</>
	);
}

export const idToNameMap: Record<string, string> = {
    '63c5c72d97f54e5960651bcc': 'Neo Química',
    '63c5c71f97f54e5960651bcb': 'Marcas',
};

export const getStatusColor = (status?: string): string => {
    return status?.includes('NO_VALID_DISTRIBUTOR') ? '#E71B30' : '#0077CB';
};

const statusTranslations: Record<string, string> = {
    'VALID': 'Possui atendimento.',
    'NO_VALID_DISTRIBUTOR': 'Sem atendimento no momento.',
    'NO_LOOPING_CONFIGURED': 'Sem distribuidores configurados.',
};

export const getTranslatedStatus = (status?: string, isIndustry: boolean = false): string => {
    if (!status) return 'Sem distribuidores disponíveis na sua região.';

    const statusParts = status.split(' | ');
    const translatedParts = statusParts.map(part => {
        if (isIndustry && part === 'NO_VALID_DISTRIBUTOR') {
            return 'Sem distribuidores disponíveis na sua região.';
        }
        return statusTranslations[part] || part;
    });

    return translatedParts.join(' | ');
};
import { css, FlattenSimpleInterpolation, getThemeMedia } from "front-commons/ds";

export default css`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  * {
    margin: 0;
    font-family: 'Source Sans 3', sans-serif;

		text-decoration: none;
  }

	*:not(html, body, #root) {
		@media screen {
			@media (min-width: ${getThemeMedia("large")}) {
				&::-webkit-scrollbar-track {
					border-radius: 8px;
					background-color: var(--surface-primary);
				}

				&::-webkit-scrollbar {
					background-color: var(--surface-primary);
					width: 8px;
					height: 8px;
				}

				&::-webkit-scrollbar-thumb {
					border-radius: 8px;
					background-color: var(--border-primary);
				}
			}
		}
	}

	#root {
		width: 100%;

		& > div {
			display: flex;
			flex-direction: column;

			min-height: 100dvh;
		}
	}

	html {
		font-size: 16px;
	}

  html, body {
    height: 100%;
  }

  body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
		background-color: var(--surface-primary);
  }

  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }

  input, button, textarea, select {
    font: inherit;
  }

  button {
    appearance: none;
    border: none;
    background-color: transparent;
  }

  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }

  #root, #__next {
		display: flex;
    flex-direction: column;

		min-height: 100vh;

    isolation: isolate;
  }

  a {
    color: var(--semantic-progress-text);
  }

	ul {
		padding: 0;
	}

	.toast-container-drawer {
		margin-left: auto;
		right: 0 !important;
		left: 0 !important;
		bottom: 0 !important;

		& > div {
			padding: 0 16px;
		}

		@media screen {
			@media (max-width: ${getThemeMedia("medium")}) {

				& > div {
					padding: 0;
					top: initial !important;
					bottom: 0 !important;
				}
			}
		}
	}

	.toast-container {
		position: sticky !important;

		transform: translateY(8px);

		z-index: 100 !important;

		@media screen {
			@media (max-width: ${getThemeMedia("medium")}) {
				position: fixed !important;

				margin-left: -16px;
				margin-bottom: -16px;

				width: 100vw;

				div {
					top: initial !important;
					bottom: 0 !important;
				}
			}
		}
	}
` as FlattenSimpleInterpolation
import { Flex, Skeleton, Typography } from 'front-commons/ds';
import { containerStyle, valueLabels } from './helpers';
import { BasketSummaryProps, ValuesProps } from './interfaces';
import { createElement } from 'react';

export default function ValuesSummary({
	tax = 0,
	brute = 0,
	liquid = 0,
	loading,
	discount = 0,
	hideValue,
	showZeroValues,
	TypographyOverwrite,
}: Partial<BasketSummaryProps>) {
	const totalLiquid = discount && tax ? brute - discount : 0;
	const total = brute - discount + tax;

	const values: ValuesProps = {
		brute,
		discount,
		totalLiquid: liquid || totalLiquid,
		tax,
		total,
	};

	return (
		<Flex direction="column" width="100%" gap="8px" data-testid="ValuesSummary">
			{Object.entries(values).map(([key, value]) => {
				const isTotal = key === 'total';
				const styleKey = isTotal ? 'total' : 'common';
				const showOperator = key === 'discount';

				const Component = TypographyOverwrite?.[key as keyof typeof TypographyOverwrite]?.element || Typography;
				const props = TypographyOverwrite?.[key as keyof typeof TypographyOverwrite]?.props || {
					color: '--neutral-black',
				};

				return (
					<Flex
						key={key}
						alignItems="center"
						{...containerStyle[styleKey]}
						// @ts-ignore
						display={(!value && !showZeroValues) || hideValue?.[key] ? 'none' : 'flex'}
						data-testid={`ValuesSummary-${key.capitalize()}`}
					>
						{/* @ts-ignore */}
						{createElement(Component?.label || Component, {
							children: valueLabels[key as keyof typeof valueLabels],
							// @ts-ignore
							...(props?.label || props),
							'data-testid': `ValuesSummary-${key.capitalize()}-Label`,
						})}

						{/* @ts-ignore */}
						{!loading &&
							createElement(Component?.value || Component, {
								children: `${showOperator ? '- ' : ''}${Number(value).toCurrency()}`,
								// @ts-ignore
								...(props?.value || props),
								'data-testid': `ValuesSummary-${key.capitalize()}-Value`,
							})}

						{loading && <Skeleton width="100px" height="12px" />}
					</Flex>
				);
			})}
		</Flex>
	);
}

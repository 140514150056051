import useCustomer from 'stores/customer';
import { CustomerAccess, CustomerRole } from 'stores/customer/interfaces';

export default function MissingDistributor() {
	const { simulatedViewType } = useCustomer();

	if ([CustomerAccess.BASIC, CustomerRole.CONSULTANT].includes(String(simulatedViewType()) as any)) {
		return (
			<>
				Entre em contato com o administrador ou comprador desta farmácia e solicite para que ele selecione os
				distribuidores. Para consultar os preços é necessário selecionar ao menos um distribuidor por divisão.
			</>
		);
	}

	return (
		<>
			Para visualizar os produtos na loja e realizar compras, é necessário configurar ao menos um distribuidor para cada
			indústria em que você possui atendimento.
		</>
	);
}

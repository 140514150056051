import { FindBasketProductsResponse } from 'services/basket/interfaces';
import { DistributorsProps, ProductParams } from 'services/products/interfaces';
import { formatProducts } from './helpers';
export * from './checkout';

const tryInitDataLayer = () => {
	if (!window.dataLayer) {
		window.dataLayer = window.dataLayer || [];
	}
};

export const pageViewGTM = (event: Record<string, string>) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(event);
};

export const viewItemListGTM = (products: any) => {
	window.dataLayer = window.dataLayer || [];

	const items = Array.isArray(products)
		? products.map(
			({
				ean13,
				productType,
				description,
				brandName,
				categoryName,
				distributors,
				businessUnitName,
				hyperaCode,
			}: any) => ({
				item_id: productType === 'SIMPLE' ? ean13 : hyperaCode,
				item_name: description,
				item_brand: brandName,
				item_variant: businessUnitName,
				item_category: categoryName,
				price: distributors.find((distributor: any) => distributor.status !== 'UNAVAILABLE')?.finalPrice || 0,
				affiliation:
					distributors.find((distributor: any) => distributor.status !== 'UNAVAILABLE')?.distributorName || '',
				quantity: 1,
			}),
		)
		: [];

	const itemsList = {
		items,
	};

	window.dataLayer.push({ ecommerce: null });
	window.dataLayer.push({ event: 'view_item_list', ecommerce: itemsList });
	return itemsList;
};

export const updateCartGTM = (type: string, value: number, products: any, format?: boolean) => {
	window.dataLayer = window.dataLayer || [];

	let items = [];

	if (format) {
		items = Array.isArray(products) ? formatProducts(products, false) : [];
	} else {
		items = Array.isArray(products)
			? products.map(
				({
					hyperaCode,
					ean13,
					description,
					brandName,
					price,
					categoryName,
					quantity,
					discount,
					affiliation,
					combo,
					productType,
					businessUnitName,
					promotion_name,
					promotion_id,
					suggestionGroupName
				}: any) => ({
					item_id: combo || productType === 'COMBO' ? hyperaCode : ean13,
					item_name: description,
					item_brand: brandName || '',
					item_variant: businessUnitName || '',
					item_category: categoryName || '',
					price,
					quantity,
					discount,
					affiliation,
					promotion_name: promotion_name || '',
					promotion_id: promotion_id || hyperaCode || '',
					...(suggestionGroupName ? { item_list_name: suggestionGroupName } : {}),
				}),
			)
			: [];
	}

	const eventData = {
		items,
	};

	window.dataLayer.push({ ecommerce: null });
	window.dataLayer.push({ event: type, value: Number(value.toFixed(2)), currency: 'BRL', ecommerce: eventData });
	return eventData;
};

export const loginGTM = (type: string) => {
	window.dataLayer = window.dataLayer || [];

	window.dataLayer.push({ event: 'login', method: type });
};

export const searchEventGTM = (search_term: string) => {
	tryInitDataLayer();
	window.dataLayer.push({ event: 'search', search_term: search_term.toLowerCase() });
};

export const sendFaqMessageGTM = (reason: string) => {
	tryInitDataLayer();
	window.dataLayer.push({ event: 'send_faq_message', reason });
};

export const signUpGTM = () => {
	tryInitDataLayer();
	window.dataLayer.push({ event: 'sign_up', method: 'generic' });
};

export const selectContentGTM = (content_type: string, content_id: string) => {
	tryInitDataLayer();
	window.dataLayer.push({ event: 'select_content', content_type, content_id });
};

export const updateLoopingProviderGTM = (
	type: 'remove_looping_provider' | 'add_looping_provider' | 'massive_looping_provider',
	{
		distributorName,
		minimumValue,
		businessUnitName,
		index,
	}: Pick<DistributorsProps, 'distributorName'> & {
		index?: number;
		minimumValue?: number;
		businessUnitName?: string;
	},
) => {
	tryInitDataLayer();

	window.dataLayer.push({
		event: type,
		index,
		provider_name: distributorName,
		minimum_value: minimumValue,
		looping_type: businessUnitName,
	});
};

export const selectProviderGTM = ({
	distributorName,
	index,
	status,
	id: productId,
}: Pick<DistributorsProps, 'distributorName' | 'status'> &
	Pick<ProductParams, 'id'> & {
		index?: number;
	}) => {
	tryInitDataLayer();

	window.dataLayer.push({
		event: 'select_provider',
		provider_name: distributorName,
		status_stock: status,
		index,
		item_id: productId,
	});
};

export const updateProviderGTM = (products: any, basketData?: FindBasketProductsResponse) => {
	tryInitDataLayer();

	const items = Array.isArray(products)
		? products.map(
			({
				id,
				ean13,
				productType,
				description,
				brandName,
				price,
				quantity,
				discount,
				affiliation,
				businessUnitName,
			}: any) => {
				const item_id =
					productType === 'COMBO'
						? basketData?.baskets
							.find((basket) =>
								basket.products.find((product) => product.productType === 'COMBO' && product.id === id),
							)
							?.products.find((product) => product.productType === 'COMBO' && product.id === id)?.hyperaCode
						: ean13;

				return {
					item_id,
					item_variant: businessUnitName,
					item_name: description,
					item_brand: brandName,
					price,
					quantity,
					discount,
					affiliation,
				};
			},
		)
		: [];

	const eventData = {
		items,
	};

	window.dataLayer.push({ ecommerce: null });
	window.dataLayer.push({ event: 'update_provider', ecommerce: eventData });
};

import { Flex } from 'front-commons/ds';
import styled from 'styled-components';

export const SuggestedProductCardContainer = styled(Flex)``;

export const ProductImg = styled.img<{ size?: `${string}px` }>`
	max-width: ${({ size }) => size || '96px'};
	max-height: ${({ size }) => size || '96px'};
	object-fit: cover;
	object-position: center;
`;

import { ThemeProvider as FCThemeProvider } from 'front-commons/ds';
import { ThemeProvider as HDSThemeProvider } from 'hype-ds';
import { HelmetProvider } from 'react-helmet-async';
import { hotjar } from 'react-hotjar';
import { BrowserRouter as Router } from 'react-router-dom';
import PolicyCookiesPopup from 'containers/Dialogs/PolicyCookiesPopup';
import Head from 'routes/Head';
import { headScripts } from 'routes/helpers';
import { getStore } from 'stores/helpers';
import Routes from './routes';
import CacheBuster from 'config/CacheBuster';
import reset from './reset';

hotjar.initialize(Number(import.meta.env.VITE_HOTJAR_ID || ''), 6);

if (hotjar.initialized()) {
	const response = getStore().customerReducer.data;

	if (response) {
		if (response?.id) hotjar.identify(response.id, { userProperty: 'value', nonce: 'hotjar' });
	}
}

function App() {
	return (
		<CacheBuster>
			{({ loading, isLatestVersion, refreshCacheAndReload }: any) => {
				if (loading) return null;
				if (!loading && !isLatestVersion) {
					// You can decide how and when you want to force reload
					refreshCacheAndReload();
				}

				return (
					<HelmetProvider>
						<FCThemeProvider isAdmin>
							<HDSThemeProvider consumer="hypera-ph-commerce" resetCSS={reset}>
								<Router>
									<Head title="Parceiro Hypera" custom={headScripts()} />
									<PolicyCookiesPopup />
									<Routes />
								</Router>
							</HDSThemeProvider>
						</FCThemeProvider>
					</HelmetProvider>
				);
			}}
		</CacheBuster>
	);
}

export default App;

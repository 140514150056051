import { Flex } from 'front-commons/ds';
import styled from 'styled-components';

export const ModalDistributorTable = styled(Flex)`
	border: 1px solid var(--border-primary);
	width: 100%;
	gap: 0;

	div:not(:last-child), div div:not(:last-child) {
		border-bottom: 1px solid var(--border-primary);
	}

	#name {
		border-bottom: none;
	}
	
	p {
		padding: 10px;
	}

	div div {
		border-right: 1px solid var(--border-primary);
	}
`;

export const Bullet = styled.span`
    width: 6px;
    height: 6px;
    border-radius: 50%;
`
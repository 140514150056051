import axios from 'axios';
import { http } from 'msw';
import { handleReject, handleRequest } from './helpers';
import { CreateAPIConnectionCbProps } from './interfaces';
import { notify } from 'front-commons/ds';

export const createAPIConnection = (serviceURL: string, callbacks?: CreateAPIConnectionCbProps) => {
	const api = axios.create({
		baseURL: `${import.meta.env.VITE_API_BASE}${serviceURL}`,
	});

	api.interceptors.request.use(
		(config) => handleRequest(config, callbacks?.onRequest),
		(error) => {
			return Promise.reject(error);
		},
	);

	let retryCount = 0;
	const maxRetries = 3;
	const retryDebounce = 500;

	api.interceptors.response.use(
		(response) => {
			if (response.status === 429) {
				const config = response.config;

				if (retryCount < maxRetries) {
					retryCount++

					return new Promise((resolve) => {
						setTimeout(() => {
							resolve(api(config))
						}, retryDebounce)
					})
				}

				notify.negative({ description: 'Muitas requisições simultâneas. Tente novamente mais tarde.' });
				Promise.reject(response)
			}

			if (response.config.baseURL?.includes('mock')) {
				const warnings = response.headers['x-front-mock-alerts'];
				if (warnings) {
					Object.entries(JSON.parse(warnings)).forEach(([key, value]) => {
						console.warn(`[Front-Mocks] \n\n uri: ${response.config.url} \n method: ${response.config.method} \n statusCode: ${response.status} \n\n Type: ${key} \n\t ${value}`,);
					});
				}
			}

			return response;
		},
		(response) => {
			callbacks?.onReject?.(response);
			handleReject(response);
		},
	);

	return api;
};

export const intercept = {
	get(pathname: string, mockFn: (responseParams?: MockResponse) => MockReturn) {
		return http.get(`${import.meta.env.VITE_API_BASE}${pathname}`, mockFn);
	},
	post(pathname: string, mockFn: (responseParams?: MockResponse) => MockReturn) {
		return http.post(`${import.meta.env.VITE_API_BASE}${pathname}`, mockFn);
	},
	put(pathname: string, mockFn: (responseParams?: MockResponse) => MockReturn) {
		return http.put(`${import.meta.env.VITE_API_BASE}${pathname}`, mockFn);
	},
	delete(pathname: string, mockFn: (responseParams?: MockResponse) => MockReturn) {
		return http.delete(`${import.meta.env.VITE_API_BASE}${pathname}`, mockFn);
	},
};

import { Flex, Grid, Skeleton } from 'front-commons/ds';
import DistributorsSelector from 'containers/Distributors/DistributorsSelector';
import TrashButton from 'containers/TrashButton';
import { Typography } from 'hype-ds';
import { ProductCardProps } from '../interfaces';
import { memo } from 'react';

export default function ProductCard({
	id,
	name,
	ean13,
	deleting,
	disabled,
	hyperaCode,
	distributors,
	initialDistributorId,
	selectedDistributorId,
	onRemove,
	onDistributorSelect,
}: ProductCardProps) {
	const distributor = distributors.find(
		({ distributorId }) => distributorId === (selectedDistributorId || initialDistributorId),
	);

	const sku = (() => {
		if (hyperaCode) {
			return {
				type: 'Promoção',
				value: hyperaCode,
			};
		}

		return {
			type: 'EAN',
			value: ean13,
		};
	})();

	return (
		<Flex
			gap="16px"
			width="100%"
			direction={{ small: 'column', medium: 'row' }}
			alignItems={{ small: 'flex-start', medium: 'center' }}
			as={Grid}
			columns={{ small: '1fr', medium: '216px 1fr' }}
			rows={{ small: 'auto', medium: 'auto' }}
		>
			<Flex direction="column" gap="0px" justifyContent="space-between">
				<Typography.Label.M title={name} textEllipsis={1}>
					{name.capitalize({ rule: 'productName' })}
				</Typography.Label.M>

				<Flex gap="2px">
					<Typography.Label.XS color="--text-secondary">{sku.type}:</Typography.Label.XS>
					<Typography.Paragraph.XS color="--text-secondary">{sku.value}</Typography.Paragraph.XS>
				</Flex>

				{!!distributor?.unitPricePromotion && (
					<Typography.Label.S>
						Por {distributor.unitPricePromotion.toCurrency()}
						{!!distributor?.taxSubstitution && ` + ${distributor.taxSubstitution.toCurrency()} imposto`}
					</Typography.Label.S>
				)}
			</Flex>

			<Flex alignItems="center" justifyContent="space-between">
				<Grid columns={{ small: '100%', medium: '228px' }}>
					<DistributorsSelector
						size="small"
						data={distributors.filter(({ status }) => status !== 'UNAVAILABLE')}
						onChange={(selected) => onDistributorSelect(id, selected.distributorId)}
						disabled={deleting === id || disabled}
						showFooter={false}
						initialValue={distributor?.status === 'UNAVAILABLE' ? undefined : distributor}
						permitEmptyValue
						watchInitialValue
					/>
				</Grid>
				<Flex
					margin="0 0 0 auto"
					as={TrashButton}
					color="--text-link"
					variant="none"
					onClick={() => onRemove(id)}
					disabled={disabled}
					{...(deleting
						? {
								disabled: deleting !== id,
								loading: deleting === id,
						  }
						: undefined)}
				/>
			</Flex>
		</Flex>
	);
}

ProductCard.Skeleton = memo(({ quantity }: { quantity: number }) => (
	<Flex gap="24px" direction="column" width="100%">
		{Array.generate(quantity).map((index) => (
			<Flex key={index} gap="16px" alignItems="center" width="100%" height="65px" wrap="wrap">
				<Flex direction="column" gap="2px">
					<Skeleton width="216px" height="14px" />
					<Skeleton width="100px" height="14px" />
					<Skeleton width="80px" height="14px" />
				</Flex>
				<Flex alignItems="center" justifyContent="space-between">
					<Skeleton width="228px" height="32px" />
					<Skeleton width="24px" height="24px" />
				</Flex>
			</Flex>
		))}
	</Flex>
));

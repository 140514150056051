import { Grid, Table, getThemeMedia, getThemeSpace } from 'front-commons/ds';
import styled from 'styled-components';

export const ManagePersonsContainer = styled(Grid)`
	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			min-height: 320px;
		}
	}

	#user-list footer {
		padding: 12px 16px;
	}
`;

export const ContentBottom = styled.div`
	margin-top: ${getThemeSpace('24')};
`;

export const PharmacySelection = styled(Grid)`
	& > h2 {
		margin-bottom: ${getThemeSpace('16')};
	}
`;

export const PharmacyInfoWrapper = styled(Grid)`
	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			padding-top: ${getThemeSpace('24')};
		}
	}
`;

export const PharmacyInfoSeparator = styled.div`
	margin-bottom: ${getThemeSpace('4')};
`;

export const PharmacyStatusSelectWrapper = styled.div`
	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			display: flex;
			justify-content: flex-end;

			& > div {
				max-width: 200px;
				width: 100%;
			}
		}
	}
`;

export const TableManagePersons = styled(Table)``;

import { getStore } from 'stores/helpers';
import { CustomerRole } from './interfaces';
import { CustomerDataResponse } from 'services/customer/interfaces';
import { insiderDispatcher } from 'services/insider';

export const customerPermissions = {
	simulated: {
		basic: [],
		action: ['BASKET_EDIT', 'DISTRIBUTOR_EDIT'],
		denied: ['BASKET_FINALIZE', 'USER_DATA_EDIT', 'ACCESS_MANAGE_EDIT', 'LIST_POS'],
	},
} as const;

export const simulatedViewType = () => {
	const { accessType, hyperaRole } = getStore().customerReducer.data;

	if (hyperaRole === CustomerRole.CONSULTANT) return CustomerRole.CONSULTANT;

	return accessType || false;
};

export const mainCustomerPage = () => (simulatedViewType() ? '/simular-farmacia' : '/minhas-farmacias');

export const sendInfosToPartners = (data: CustomerDataResponse) => {
	const { email, name, phoneNumberConfirmed, phoneNumber } = data;

	insiderDispatcher.user({
		name,
		email,
		email_optin: true,
		phone_number: phoneNumberConfirmed ? `+55${phoneNumber}` : undefined,
		sms_optin: phoneNumberConfirmed,
	});
}
import { Button, Flex, Icon, Typography, notify } from 'front-commons/ds';
import { useState } from 'react';
import useCustomer from 'stores/customer';
import { DistributorCardDummyProps, DistributorCardConditions, DistributorCardActiveProps } from './interfaces';
import DistributorCardSkeleton from './Skeleton';
import { ButtonSelectWrapper, Container, DummyContainer, NameWrapper } from './styles';

export default function DistributorCard({ dummy, loading, active, isPed }: DistributorCardConditions) {
	if (loading) return <DistributorCardSkeleton />;
	if (dummy) return <DistributorCard.Dummy {...dummy} />;
	return <DistributorCard.Active {...active} isPed={isPed} />;
}

DistributorCard.Active = ({
	id,
	order,
	index,
	isPed,
	disabled = false,
	isTablet,
	hideOrder,
	minimumValue,
	hideMinValue = false,
	dragSnapshot,
	dragProvided,
	hasPermission,
	selectedLength,
	commercialCenter,
	summarizedCorporateName,
	onAdd,
	onRemove,
	onReorder,
}: DistributorCardActiveProps) => {
	const isSelected = !hideOrder && Number.isInteger(order);
	const [animate, setAnimate] = useState(false);

	const props =
		isTablet && hasPermission
			? {
					ref: dragProvided.innerRef,
					...dragProvided.dragHandleProps,
					...dragProvided.draggableProps,
					...dragSnapshot,
			  }
			: undefined;

	const handleClick = () => {
		if (isTablet || !hasPermission) return;

		if (disabled) {
			notify.info({ description: 'Você já selecionou o numero máximo de distribuidores' });
			return;
		}

		if (isSelected) {
			onRemove?.(id);
			return;
		}

		setAnimate(true);

		setTimeout(() => {
			onAdd?.(id);
		}, 300);
	};

	const distributorDisplayName = `${summarizedCorporateName} ${
		commercialCenter ? `(${commercialCenter?.toUpperCase()})` : ''
	}`;

	return (
		<Container
			data-testid={`distributor-card-${isSelected ? 'selected' : 'unselected'}`}
			data-index={index}
			isSelected={isSelected}
			disabled={disabled}
			onClick={handleClick}
			animation={animate}
			index={index}
			{...props}
		>
			<Flex gap="8px" alignItems="center" justifyContent="space-between" data-index={index} width="100%">
				<Flex
					alignItems="center"
					gap="16px"
					data-index={index}
					width={{
						small: isSelected ? 'calc(100% - 32px)' : '100%',
						medium: isSelected ? 'calc(100% - 8px - 20px)' : 'calc(100% - 8px - 20px)',
					}}
				>
					{isSelected && (
						<Typography style={{ fontSize: '18px' }} color="--semantic-info-text" variant="Paragraph/Semibold">{`0${
							index! + 1
						}`}</Typography>
					)}
					<Flex direction="column" gap="0px" data-index={index} width={isSelected ? 'calc(100% - 32px)' : '100%'}>
						<NameWrapper>
							<Typography
								variant="Paragraph/Semibold"
								as="span"
								color="--text-primary"
								data-index={index}
								title={distributorDisplayName}
							>
								{distributorDisplayName}
							</Typography>
						</NameWrapper>
						{!hideMinValue && (
							<Typography variant="ParagraphSmall/Regular" color="--text-primary" data-index={index}>
								Valor mínimo: {Number(minimumValue).toCurrency()}
							</Typography>
						)}
					</Flex>
				</Flex>
				{isSelected ? (
					<Flex
						direction="column"
						margin={{ small: '6px 0 8px 0', medium: '0 0 8px 0' }}
						maxHeight="77px"
						gap="0px"
						display={{ small: isPed ? 'none' : 'flex', medium: 'none' }}
					>
						<Button
							className="remove-style"
							onClick={(el) => {
								el.stopPropagation();
								onReorder?.(index!, index! - 1);
							}}
							variant="text"
							disabled={index === 0}
							hasPermission={hasPermission}
						>
							<Icon
								color={index === 0 ? '--text-disable' : '--semantic-info-text'}
								name="expand_circle_up"
								size="20px"
							/>
						</Button>
						<Button
							className="remove-style"
							onClick={(el) => {
								el.stopPropagation();
								onReorder?.(index!, index! + 1);
							}}
							variant="text"
							disabled={index! + 1 === selectedLength}
							hasPermission={hasPermission}
						>
							<Icon
								name="expand_circle_down"
								color={index! + 1 === selectedLength ? '--text-disable' : '--semantic-info-text'}
								size="20px"
							/>
						</Button>
					</Flex>
				) : (
					<ButtonSelectWrapper>
						<Button
							className="remove-style"
							variant="none"
							disabled={disabled}
							onClick={() => onAdd?.(id)}
							hasPermission={hasPermission}
						>
							<Icon
								color="--text-link"
								name="expand_circle_up"
								size="20px"
								alt="Botão com função de adicionar distribuidor"
							/>
						</Button>
					</ButtonSelectWrapper>
				)}
			</Flex>
		</Container>
	);
};

DistributorCard.Dummy = ({ position, isVisible = true }: DistributorCardDummyProps) => (
	<DummyContainer isVisible={isVisible}>
		<Flex alignItems="center" gap="16px">
			<Typography style={{ fontSize: '18px' }} color="--text-secondary">
				0{position}
			</Typography>
			<Typography color="--text-secondary">Distribuidor selecionado</Typography>
		</Flex>
	</DummyContainer>
);

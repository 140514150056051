import { Tooltip, Typography } from 'front-commons/ds';
import { RoleTypes, StatusTypes } from 'stores/pos/interfaces';
import { PharmacyAddressParams, PharmacyParams } from './interfaces';
import { PharmacyInfoSeparator } from './styles';

export const statusOptions = [
	{
		value: 'READY',
		label: 'Aprovado',
	},
	{
		value: 'BLOCKED',
		label: 'Bloqueado',
	},
	{
		value: 'REJECTED',
		label: 'Reprovado',
	},
	{
		value: 'ALL',
		label: 'Todos',
	},
];

export const getPosOptions = (pointsOfSale: PharmacyParams[]) => {
	return pointsOfSale.map((pharmacy) => ({
		value: pharmacy.pointOfSaleId,
		label: `${CNPJ(pharmacy.cnpj).mask} ${pharmacy.tradeName}`,
	}));
};

export const getPharmacyAddress = ({ address, number, district, city, region }: PharmacyAddressParams) => {
	return `${address}, ${number} - ${district}, ${city} - ${region}`;
};

export const getPharmacyInfo = (value: string) => {
	const pharmacyInfo: PharmacyParams = JSON.parse(value);

	return (
		<Typography>
			<Typography as="span" variant="Paragraph/Semibold">
				{CNPJ(pharmacyInfo.cnpj).mask}
			</Typography>{' '}
			{pharmacyInfo.tradeName}
			<PharmacyInfoSeparator />
			{getPharmacyAddress(pharmacyInfo.pointOfSaleAddress)}
		</Typography>
	);
};

export const columns = {
	name: { name: 'Nome do usuário' },
	email: { name: 'E-mail' },
	role: { name: 'Perfil' },
	status: { name: 'Status' },
	actions: { name: '' },
};

export const roleParser = (role: RoleTypes) => {
	switch (role) {
		case 'BUYER': {
			return 'Comprador';
		}
		case 'SUPERVISOR': {
			return 'Supervisor';
		}

		default: {
			return 'Administrador';
		}
	}
};

export const statusParser = (status: StatusTypes) => {
	switch (status) {
		case 'REJECTED': {
			return <Typography>Reprovado</Typography>;
		}
		case 'PENDING': {
			return (
				<Tooltip content="Aguardando aprovação do administrador.">
					<Typography>Aprovação pendente</Typography>
				</Tooltip>
			);
		}
		case 'BLOCKED': {
			return <Typography>Bloqueado</Typography>;
		}
		default: {
			return <Typography>Aprovado</Typography>;
		}
	}
};

export const getOptionsItemsByStatus = (
	status: StatusTypes,
	callback: (status: StatusTypes, userId: string[]) => void,
	id: string,
	role: RoleTypes,
) => {
	switch (status) {
		case 'REJECTED': {
			return [
				{
					label: 'Aprovar',
					onClick() {
						callback('READY', [id]);
					},
				},
			];
		}
		case 'PENDING': {
			return [
				{
					label: 'Aprovar',
					onClick() {
						callback('READY', [id]);
					},
				},
				{
					label: 'Reprovar',
					onClick() {
						callback('REJECTED', [id]);
					},
				},
			];
		}
		case 'BLOCKED': {
			return [
				{
					label: 'Desbloquear',
					onClick() {
						callback('READY', [id]);
					},
				},
			];
		}
		default: {
			if (role !== 'SUPERVISOR') {
				return [
					{
						label: 'Bloquear',
						onClick() {
							callback('BLOCKED', [id]);
						},
					},
				];
			}
			return [];
		}
	}
};

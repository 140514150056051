import { Flex, Grid, Typography } from 'front-commons/ds';
import { Bullet, ModalDistributorTable } from './styles';
import { getStatusColor, getTranslatedStatus, idToNameMap } from './helper';

const DistributorsError: React.FC<DistributorsErrorProps> = ({ fromButton, content }) => {
	const validContent = Array.isArray(content) ? {} : content;

	const businessUnities = validContent?.businessUnities || [];
	const industries = validContent?.industries || [];

	const expectedIds = Object.keys(idToNameMap).sort((a, b) => idToNameMap[a].localeCompare(idToNameMap[b]));

	const presentIds = new Set(businessUnities.map(({ id }) => id));

	const missingBusinessUnities = expectedIds
		.filter((id) => !presentIds.has(id))
		.map((id) => ({
			id,
			status: 'NO_VALID_DISTRIBUTOR',
		}));

	const orderedBusinessUnities = [
		...businessUnities.map(({ id, status }) => ({
			name: idToNameMap[id ?? ''] || 'Nome Desconhecido',
			status,
			isIndustry: false,
		})),
		...missingBusinessUnities.map(({ id, status }) => ({
			name: idToNameMap[id],
			status,
			isIndustry: false,
		})),
	].sort((a, b) => a.name.localeCompare(b.name));

	const orderedIndustries = industries
		.map(({ id, status, name }) => ({
			name: name ?? 'Nome Desconhecido',
			status,
			isIndustry: true,
		}))
		.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));

	const rows = [...orderedBusinessUnities, ...orderedIndustries];

	return (
		<Flex direction="column" gap="16px">
			<Typography variant="ParagraphSmall/Regular">
				{fromButton
					? 'Veja abaixo de quais indústrias essa farmácia pode comprar:'
					: 'No momento esta farmácia não está liberada para comprar produtos de Hypera e Indústrias. Confira os motivos abaixo: '}
			</Typography>
			<ModalDistributorTable direction="column">
				{rows.map((row, index) => (
					<Grid key={index} gap="0" columns="1fr 1fr">
						<Flex alignItems="center" gap="0" padding="0 0 0 15px" id="name">
							<Bullet
								style={{
									backgroundColor: getStatusColor(row?.status),
									display: 'flex',
								}}
							/>
							<Typography variant="ParagraphSmall/Regular">{row?.name}</Typography>
						</Flex>
						<Typography variant="ParagraphSmall/Regular">{getTranslatedStatus(row.status, row.isIndustry)}</Typography>
					</Grid>
				))}
			</ModalDistributorTable>
			<Typography variant="ParagraphSmall/Regular">
				Para saber mais sobre o status de compra da farmácia entre em contato conosco através do número{' '}
				<b>0800 575 0020</b>.
			</Typography>
		</Flex>
	);
};

export default DistributorsError;

import React from 'react';
import { Dispatch } from '@reduxjs/toolkit';
import {
	setCleanPos,
	setMyPos,
	setPosDistributors,
	setPosLoading,
	setSelectedPos,
	setUpdateDistributor,
	setHasDistributor,
	managePosRegisterData,
} from './actions';
import type { PosParams, PosReducer } from './interfaces';
import { getStore } from 'stores/helpers';
import { findSelectedDistributors, pharmacyDistributorsCheck, posDistributorsCheck } from 'services/pos';
import { getBUs } from 'services/catalog';
import { HandleOpenDialogProps } from 'stores/dialog/interfaces';
import { Location, NavigateFunction } from 'react-router-dom';
import { handleSetCatalogMenuItems } from 'stores/catalog/middleware';
import { DistributorsSourceParams } from 'pages/pos/Distributors/interfaces';
import { handleNavigateToFidelityPage } from 'stores/fidelity/middleware';
import { distributorsMissingDialog, sellerViewErrorNotification } from './helpers';
import { insiderDispatcher } from 'services/insider';
import { mainCustomerPage, simulatedViewType } from 'stores/customer/helpers';
import { CustomerAccess, CustomerRole } from 'stores/customer/interfaces';
import { notify } from 'front-commons/ds';
import { HandleGetBasketDataParams } from 'stores/basket/interfaces';
import DistributorsError from 'containers/Dialogs/DistributorsError'
import { BuOrIndustriesProps } from 'services/pos/interfaces';

export const handleSetMyPos = (myPos: PosParams[]): any => {
	return (dispatch: Dispatch) => {
		dispatch(setMyPos(myPos));
	};
};

export const handleClearSelectedPos = (): any => {
	return (dispatch: Dispatch) => {
		dispatch(setSelectedPos({} as PosReducer['selectedPos']));
	};
};

export const handleSetUpdateDistributor = (distributor: DistributorsSourceParams): any => {
	return (dispatch: Dispatch) => {
		dispatch(setUpdateDistributor(distributor));
	};
};

export const handleSetPosLoading = (loading: PosReducer['loading']): any => {
	return (dispatch: Dispatch) => {
		dispatch(setPosLoading(loading));
	};
};

export const handleSetPosDistributors = (distributors: Concrete<PosReducer['selectedPos']['distributors']>): any => {
	return (dispatch: Dispatch) => {
		dispatch(setPosDistributors(distributors));
	};
};

export const handleSetCleanPos = (): any => {
	return (dispatch: Dispatch) => {
		dispatch(setCleanPos());
	};
};

const getPharmacyById = (posId?: string) => {
	if (!posId) return { id: '' };

	const {
		pointOfSaleId: id,
		role,
		pointOfSaleCustomerId,
	} = getStore().posReducer.myPos.find((pos) => pos.pointOfSaleId === posId) || {
		pointOfSaleId: '',
	};

	return { id, role, pointOfSaleCustomerId };
};

export const handleSetHasDistributor = (hasDistributor: boolean): any => {
	return (dispatch: Dispatch) => {
		dispatch(setHasDistributor(hasDistributor));
	};
};

export const handleSetSelectedPos = (
	posId: string,
	handleFidelity: any,
	handleGetBasketData: (params: Pick<HandleGetBasketDataParams, 'callGTM' | 'loading' | 'initializeBefore' | 'posId'>) => void,
	handleOpenDialog: (dialog: HandleOpenDialogProps) => Promise<any>,
	navigate: NavigateFunction,
	location: Location,
	isDistributorsPage?: boolean,
	openSideBasket?: boolean,
): any => {
	return async (dispatch: Dispatch) => {
		dispatch(handleSetHasDistributor(false));
		const { role, pointOfSaleCustomerId } = getPharmacyById(posId);
		const id = posId;

		try {
			dispatch(setPosLoading(id));
			const response = await pharmacyDistributorsCheck(id);

			if (!response || typeof response !== 'object') {
				throw new Error('Resposta inesperada da API');
			}

			const businessUnities = response.businessUnities || [];
			const industries = response.industries || [];
			const BUAndIndustries = [...businessUnities, ...industries];

			const hasValidBU = businessUnities.some((item: { id: string; status: string }) => item.status === "VALID");
			const hasValidIndustry = industries.some((item: { id: string; status: string }) => item.status === "VALID");
			const hasValidDistributor = hasValidBU || hasValidIndustry;
			const formattedResponse = {
				businessUnities: businessUnities.map((item: BuOrIndustriesProps) => ({
					id: item.name,
					name: item.name || 'Nome Desconhecido',
					status: item.status,
				})),
				industries: industries.map((item: BuOrIndustriesProps) => ({
					id: item.id,
					name: item.name || 'Nome Desconhecido',
					status: item.status,
				})),
			};


			const hasInvalidBusinessUnities = businessUnities.some((item: { status: string }) => item.status === "NO_LOOPING_CONFIGURED");
			const hasNoDistributorForIndustry = industries.length > 0 && !hasValidIndustry;

			// Caso haja BUs sem looping configurado → Redireciona para alteração de distribuidores
			if (hasInvalidBusinessUnities) {
				const isSimulatedBasic = [CustomerRole.CONSULTANT, CustomerAccess.BASIC].includes(
					String(simulatedViewType()) as any
				);

				handleOpenDialog({
					...distributorsMissingDialog,
					footer: {
						...distributorsMissingDialog.footer,
						primaryButton: {
							label: isSimulatedBasic ? 'Entendi' : 'Ir para distribuidores',
							onClick: () =>
								navigate(isSimulatedBasic ? '/simular-farmacia' : `/minhas-farmacias/distribuidores?f=${posId}`, {
									state: isSimulatedBasic ? undefined : { buId: BUAndIndustries[0]?.id },
									replace: isSimulatedBasic,
								}),
						},
					},
				});
				return null;
			}

			// Caso tenha apenas Indústria, mas nenhuma com distribuidor válido → Bloqueia e informa o usuário
			if (hasNoDistributorForIndustry && !hasValidBU) {
				console.log("BUAndIndustries", BUAndIndustries)
				handleOpenDialog({
					heading: {
						title: 'Compra indisponível para essa farmácia no momento.',
						showCloseButton: true,
						typographyProps: { variant: 'Paragraph/Semibold' }
					},
					content: {
						description: React.createElement(DistributorsError, { content: formattedResponse || [] }),
					},
					footer: {
						primaryButton: {
							label: 'Ok, entendi',
						},
					},
					maxWidth: '620px',
				});
				return null;
			}

			// Caso haja distribuidores ou indústrias válidas, permitir a compra
			if (BUAndIndustries.length > 0 && hasValidDistributor) {
				const data = await getBUs(id);
				dispatch(handleSetHasDistributor(true));
				dispatch(handleFidelity({ posId: id, role }));
				handleGetBasketData({ loading: 'full', initializeBefore: true, posId: id });

				const posData: any = { id, role, businessUnities: data };

				if (businessUnities.length > 0 && hasValidBU) {
					posData.distributors = await findSelectedDistributors({ posId: id });
				}

				await dispatch(setSelectedPos(posData));
				await dispatch(handleSetCatalogMenuItems(posData));

				if (pointOfSaleCustomerId) {
					insiderDispatcher.user({ uuid: pointOfSaleCustomerId });
				}

				const startedFrom = location.state?.startedFrom;
				if (startedFrom) {
					if (startedFrom === '/fidelidade') {
						dispatch(handleNavigateToFidelityPage(posId));
						navigate('/');
					} else {
						navigate(startedFrom, { state: null });
					}
				}

				if (isDistributorsPage && !!simulatedViewType() && !startedFrom) {
					navigate('/', { state: openSideBasket ? { openSideBasket: true } : undefined });
				}

				if (window.location.pathname.includes(mainCustomerPage()) && !startedFrom) {
					navigate('/', { state: openSideBasket ? { openSideBasket: true } : undefined });
				}

				if (window.location.pathname.includes('pedido-finalizado') && !startedFrom) {
					navigate('/', { replace: true });
				}

				localStorage.setItem('searchModal', JSON.stringify(true));
				return null;
			}

			// Se não há distribuidores válidos, redirecionar para alterar distribuidores
			if (!hasValidDistributor) {
				const isSimulatedBasic = [CustomerRole.CONSULTANT, CustomerAccess.BASIC].includes(
					String(simulatedViewType()) as any
				);

				handleOpenDialog({
					...distributorsMissingDialog,
					footer: {
						...distributorsMissingDialog.footer,
						primaryButton: {
							label: isSimulatedBasic ? 'Entendi' : 'Ir para distribuidores',
							onClick: () =>
								navigate(isSimulatedBasic ? '/simular-farmacia' : `/minhas-farmacias/distribuidores?f=${posId}`, {
									state: isSimulatedBasic ? undefined : { buId: BUAndIndustries[0]?.id },
									replace: isSimulatedBasic,
								}),
						},
					},
				});
				return BUAndIndustries;
			}

			return null;

		} catch (error) {
			console.error('Erro ao buscar distribuidores:', error);

			if ((error as any)?.code === 403) {
				handleOpenDialog({
					heading: {
						title: 'Compra indisponível para essa farmácia no momento.',
						showCloseButton: true,
					},
					content: {
						description: React.createElement(DistributorsError),
					},
					footer: {
						primaryButton: {
							label: 'Ok, entendi',
						},
					},
					maxWidth: '620px',
				});
				return null;
			}

			if (isDistributorsPage) {
				if (simulatedViewType()) {
					notify.negative({
						description:
							' Esta farmácia está fora do painel comercial. Em caso de dúvida, entre em contato com a área de Efetividade.',
					});
				} else {
					notify.negative({
						description: React.createElement(sellerViewErrorNotification),
					});
				}
			} else {
				notify.negative({
					description:
						'Não foi possível executar essa ação. Por favor, entre em contato conosco para prosseguir com essa farmácia.',
				});
			}

			return null;
		} finally {
			dispatch(setPosLoading(false));
		}
	};
};


export const handleManagePosRegisterData = (action: 'SET' | 'CLEAR', data?: string): any => {
	return (dispatch: Dispatch) => {
		dispatch(managePosRegisterData(action, data));
	};
};

import { Flex, Icon, Typography } from 'front-commons/ds';
import { Container, Content, Item, Trigger } from '../SourceCard/styles';
import ValuesSummary from 'containers/Summaries/ValuesSummary';
import { BasketSummaryProps } from 'containers/Summaries/ValuesSummary/interfaces';
import { createElement } from 'react';

export default function CartTotals({
	tax,
	brute,
	liquid,
	discount,
	TypographyOverwrite,
}: Pick<BasketSummaryProps, 'brute' | 'liquid' | 'discount' | 'tax'> &
	Partial<Pick<BasketSummaryProps, 'TypographyOverwrite'>>) {
	const totalStyles = {
		label: {
			element: TypographyOverwrite?.total?.element?.label || TypographyOverwrite?.total?.element || Typography,
			// @ts-ignore
			props: TypographyOverwrite?.total?.props?.label || TypographyOverwrite?.total?.props,
		},
		value: {
			element: TypographyOverwrite?.total?.element?.value || TypographyOverwrite?.total?.element || Typography,
			// @ts-ignore
			props: TypographyOverwrite?.total?.props?.value ||
				TypographyOverwrite?.total?.props || { style: { fontSize: 18, fontWeight: 600 } },
		},
	};

	return (
		<Flex direction="column" gap="8px" width="100%">
			<Container data-testid="side-basket-Footer-Cart-totals-Container">
				<Item
					gap="0"
					value="cart-totals"
					data-testid="side-basket-Footer-Cart-totals-Item"
					width="100%"
					direction="column"
				>
					<Trigger
						data-testid="side-basket-Footer-Cart-totals-Trigger"
						gap="4px"
						alignItems="center"
						cursor="pointer"
						width="100%"
						padding="0"
					>
						<Typography color="--text-link" variant="ParagraphSmall/Semibold">
							Resumo do pedido
						</Typography>

						<Icon name="expand_more" size="16px" color="--text-link" weight={600} margin="2px 0 0" />
					</Trigger>

					<Content gap="8px" data-testid="side-basket-Footer-Cart-totals-Content" direction="column">
						<Flex padding="16px 0 0">
							<ValuesSummary
								tax={tax}
								brute={brute}
								discount={discount}
								hideValue={{ total: true }}
								TypographyOverwrite={TypographyOverwrite}
							/>
						</Flex>
					</Content>
				</Item>
			</Container>

			<Flex
				padding="8px 0 0"
				border="1px solid"
				borderWidth="1px 0 0"
				borderColor="--border-primary"
				justifyContent="space-between"
				alignItems="center"
			>
				{createElement(totalStyles.label.element, { children: 'Total', ...totalStyles.label.props })}
				{createElement(totalStyles.value.element, { children: liquid.toCurrency(), ...totalStyles.value.props })}
			</Flex>
		</Flex>
	);
}

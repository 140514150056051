import { api } from "../"
import { BusinessUnitResponse } from "pages/pos/Distributors/interfaces";
import { requestHandler } from "services/helpers"
import {
	//! DISTRIBUTORS Core
	DeleteDistributorsRequest,
	DistributorSelectOrderRequest,
	OrderOrAddDistributorsRequest,
	PharmacyDistributorsCheckRequest,
	DistributorsOrderResponse,
	//! DISTRIBUTORS Massive
	GetDistributorsFiltersResponse,
	GetMassiveDistributorsListRequest,
	GetMassiveDistributorsListResponse,
	GetMassiveProcessingStatusResponse,
	GetMassiveProcessingStatusRequest,
	SaveMassiveDistributorsRequest,
	SaveMassiveDistributorsResponse,
	//! DISTRIBUTORS Industries [SIMPLE]
	GetIndustriesAvailableDistributorsRequest,
	GetIndustriesAvailableDistributorsResponse,
	GetIndustriesSelectedDistributorsRequest,
	GetIndustriesSelectedDistributorsResponse,
	GetPosIndustriesResponse,
	//! DISTRIBUTORS Industries [MASSIVE]
	GetMassivePosIndustriesResponse,
	GetMassiveIndustriesAvailableDistributorsRequest,
	GetMassiveIndustriesAvailableDistributorsResponse,
} from "./interfaces"

//! DISTRIBUTORS Core

export const findDistributors = ({ posId }: { posId: string }) => {
	return requestHandler<BusinessUnitResponse[]>(api.get(`/distributors/pos/${posId}`));
};

export const findSelectedDistributors = ({ posId }: { posId: string }) => {
	return requestHandler<BusinessUnitResponse[]>(api.get(`/pos/${posId}/distributors`));
};

export const orderOrAddDistributors = ({ id, data }: OrderOrAddDistributorsRequest) => {
	return requestHandler(api.post(`/pos/${id}/distributors`, data));
};

export const deleteDistributors = ({ id, distributors: data }: DeleteDistributorsRequest) => {
	return requestHandler(api.delete(`/pos/${id}/distributors`, { data }));
};

export const distributorSelectOrder = ({ posId, data }: DistributorSelectOrderRequest) => {
	return requestHandler(api.post(`/pos/${posId}/distributors/exibition`, data));
};

export const posDistributorsCheck = (posId: string) => {
	return requestHandler<PharmacyDistributorsCheckRequest>(api.get(`/pos/${posId}/distributors/check`, {
		headers: { 'X-Marketplace': true },
	}));
};

export const getDistributorsOrder = (posId: string) => {
	return requestHandler<DistributorsOrderResponse>(api.get(`/pos/${posId}/distributors/order`));
};


//! DISTRIBUTORS Massive

export const getDistributorsFilters = () => {
	return requestHandler<GetDistributorsFiltersResponse>(api.get('/pos/distributors/filters'));
}

export const getMassiveDistributorsList = (filter: GetMassiveDistributorsListRequest) => {
	return requestHandler<GetMassiveDistributorsListResponse>(api.post('/pos/distributors/search', { filter }))
}

export const saveMassiveDistributors = (data: SaveMassiveDistributorsRequest) => {
	return requestHandler<SaveMassiveDistributorsResponse>(api.post('/pos/distributors/save-massive', data))
}

export const getMassiveProcessingStatus = ({ loopingProcessingId }: GetMassiveProcessingStatusRequest) => {
	return requestHandler<GetMassiveProcessingStatusResponse>(api.get(`/pos/distributors/massive/${loopingProcessingId}`))
}

//! DISTRIBUTORS Industries [SIMPLE]

export const getPosIndustries = ({ posId }: { posId: POSEntity['id'] }) => {
	return requestHandler<GetPosIndustriesResponse[]>(api.get(`/pos/${posId}/industries/valid`))
}

export const getIndustriesSelectedDistributors = ({ posId, industryId }: GetIndustriesSelectedDistributorsRequest) => {
	return requestHandler<GetIndustriesSelectedDistributorsResponse>(api.get(`/pos/${posId}/distributors/industries/${industryId}`))
}

export const getIndustriesAvailableDistributors = ({ posId, industryId }: GetIndustriesAvailableDistributorsRequest) => {
	return requestHandler<GetIndustriesAvailableDistributorsResponse>(api.get(`/distributors/pos/${posId}/industries/${industryId}`))
}

//! DISTRIBUTORS Industries [MASSIVE]

export const getMassivePosIndustries = (data: { posIds: POSEntity['id'][] }) => {
	return requestHandler<GetMassivePosIndustriesResponse[]>(api.post(`/pos/industries/valid`, data))
}

export const getMassiveIndustriesAvailableDistributors = ({ industryId, ...data }: GetMassiveIndustriesAvailableDistributorsRequest) => {
	return requestHandler<GetMassiveIndustriesAvailableDistributorsResponse>(api.post(`/pos/distributors/industries/${industryId}/coverage`, data))
}
import dayjs from 'dayjs';
import { useWhenMounted } from 'front-commons/hooks';
import getRecursion from 'pages/products/OrderImport/helpers';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useBasket from 'stores/basket';
import useDialog from 'stores/dialog';
import useDrawer from 'stores/drawer';
import useOrderImport from 'stores/orderImport';
import usePos from 'stores/pos';

export default function HelperComponent() {
	const location = useLocation();

	const {
		handleInitializeBasket,
		basketStore: { validTill },
	} = useBasket();
	const { posStore, handleSetMyPos } = usePos();
	const { handleCloseAllDrawers } = useDrawer();
	const { handlePreventDataLoss } = useDialog();
	const { orderImportStore, handleClearPosPromotions } = useOrderImport();
	const { drawerStore } = useDrawer();

	const pos = posStore.selectedPos;

	const handleValidateOrderImportPromotionsState = () => {
		if (['finalizar-pedido', 'customizacao-de-pedido'].includes(location.pathname)) return;
		if (!Object.keys(orderImportStore.posPromotions).length) return;
		handleClearPosPromotions();
	};

	const handleEnsureBasketReducer = () => {
		if (!JSON.parse(localStorage.getItem('persist:@PHC') || '{}')?.basketReducer) {
			localStorage.clear();
			window.location.reload();
			// validar essa parte com o Léo (20/08/2024)
		}
	};

	const handleEnsurePreventDataLossIsDisabled = () => {
		if (handlePreventDataLoss.isEnabled) handlePreventDataLoss.disable();
	};

	useWhenMounted(() => {
		if (pos.id && (!validTill || dayjs().unix() > validTill)) handleInitializeBasket();

		// TEMP: Is the better way destroy pos state and use only myPharmacies state
		handleSetMyPos([...posStore.myPos.map((posMap) => ({ ...posMap, isSelected: posMap.pointOfSaleId === pos?.id }))]);
	}, [pos]);

	useEffect(() => {
		getRecursion.handleStopRecursion();

		handleValidateOrderImportPromotionsState();
		handleEnsureBasketReducer();
		handleEnsurePreventDataLossIsDisabled();
		handleCloseAllDrawers();
	}, [location.pathname]);

	useEffect(() => {
		if (drawerStore.drawers.some(({ open }) => open)) {
			document.body.style.overflow = 'hidden';
			return;
		}

		document.body.style.overflow = 'auto';
	}, [drawerStore]);

	return null;
}

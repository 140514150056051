import { Suspense, useEffect, useMemo, useCallback } from 'react';
import { Route, Routes as Router, useLocation } from 'react-router-dom';
import DialogsRoot from 'containers/Dialogs/Root';
import SideDrawers from 'containers/Drawers/RegisteredDrawers';
import HelperComponent from 'containers/HelperComponent';
import LoadingPage from 'containers/LoadingPage';
import { PromotionProvider } from 'contexts/Promotion';
import Error404 from 'pages/Error/Error404';
import { onEnvironment } from 'shared/environment';
import { pageViewGTM } from 'shared/gtm';
import useFeatures from 'stores/features';
import ErrorBoundary from './ErrorBoundary';
import { getDialogData, providerHandler } from './helpers';
import ProtectedRoute from './ProtectedRoute';
import ScrollToTop from './ScrollToTop';
import { getModalData } from 'services/modal';
import { theme } from 'front-commons/ds/core/tokens';
import { useMediaQuery } from 'front-commons/hooks';
import useDialog from 'stores/dialog';
import { HandleOpenDialogProps } from 'stores/dialog/interfaces';
import usePos from 'stores/pos';
import pagesMapping from './pages';
import ModalController from './components/ModalController';

export default function Routes() {
	const location = useLocation();
	const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.medium})`);
	const { featuresStore } = useFeatures();

	const { handleCloseDialog, handleOpenDialog } = useDialog();
	const { posStore } = usePos();
	const pharmacy = posStore.selectedPos;

	const parsedPages = useMemo(() => {
		if (onEnvironment(['production'])) return pagesMapping;

		return pagesMapping.filter(({ featureFlag }) => {
			if (!featureFlag) return true;

			return featuresStore[featureFlag];
		});
	}, [featuresStore]);

	useEffect(() => {
		pageViewGTM({ event: 'pageView' });
	}, [location]);

	const getModal = useCallback(async () => {
		const searchModal = JSON.parse(localStorage.getItem('searchModal') || 'false');

		if (searchModal) {
			try {
				const response = await getModalData(pharmacy.id);

				const dialogData = getDialogData(response.content, isDesktop, handleCloseDialog);
				handleOpenDialog(dialogData as HandleOpenDialogProps);
			} catch (error) {
				console.log('error');
			} finally {
				localStorage.removeItem('searchModal');
			}
		}
	}, [location, pharmacy]);

	useEffect(() => {
		getModal();
	}, [getModal]);

	useEffect(() => {
		pageViewGTM({ event: 'pageView' });
	}, [location]);

	return (
		<Suspense fallback={<LoadingPage />}>
			<ErrorBoundary isGlobalBoundary>
				<div>
					<ScrollToTop>
						<PromotionProvider>
							<ErrorBoundary>
								<ModalController location={location} />
								<HelperComponent />
								<SideDrawers />
								<DialogsRoot />
								<Router>
									{parsedPages
										.filter(({ enabled }) => enabled === undefined || enabled)
										.map(({ page, pathname, ...rest }) => (
											<Route key={pathname} element={<ProtectedRoute {...rest} />}>
												<Route index={pathname === '/'} path={pathname} element={providerHandler({ page, ...rest })} />
											</Route>
										))}
									<Route path="*" element={<Error404 />} />
								</Router>
							</ErrorBoundary>
						</PromotionProvider>
					</ScrollToTop>
				</div>
			</ErrorBoundary>
		</Suspense>
	);
}

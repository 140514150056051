import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { BusinessUnitProps, ProductUnavailableProps } from 'services/basket/interfaces';
import { getStore } from 'stores/helpers';
import { Redux } from 'stores/interfaces';
import { BasketReducer, HandleGetBasketDataParams } from './interfaces';
import * as middleware from './middleware';
import useDrawer from 'stores/drawer';
import { updateCartGTM, onSideBasketOpen } from 'shared/gtm';
import { clearSelectedBaskets } from 'services/basket';
import { notify } from 'front-commons/ds';

export default function useBasket() {
	const basketStore = useSelector((stores: Redux) => stores.basketReducer);

	const { pathname } = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { handleOpenDrawer, isDrawerOpen } = useDrawer();

	const isEndBasketPage = pathname === '/revisar-pedido';
	const isOpenBasket = isDrawerOpen('side-basket-drawer');
	const {
		selectedPos: { id: posId },
	} = getStore().posReducer;

	const handleOpenBasket = () => {
		handleOpenDrawer('side-basket-drawer');

		if (basketStore.basket?.baskets)
			onSideBasketOpen({
				totalPricePromotion: Number(basketStore.basket?.totalPricePromotion),
				basketProductsData: basketStore.basket?.baskets?.map((basket) =>
					basket.products.map((product) => ({
						...product,
						affiliation: basket.distributorName,
						businessUnitName: basket.businessUnitName,
					})),
				),
			});
	};

	const handleClearChangedUnavailable = () => dispatch(middleware.handleSetChangedUnavailable([]));

	const handleRemoveFromBasket = async (productId: string, replacePosId?: string) => {
		await dispatch(
			middleware.handleRemoveProduct({
				posId: replacePosId || posId,
				productId,
				basketStore,
				isEndBasketPage,
			}),
		);
	};

	const setChangedUnavailable = (products: ProductUnavailableProps[] | undefined) => {
		dispatch(middleware.handleSetChangedUnavailable(products || []));
	};

	const unavailableHandler = {
		get(redirectToAdjust = false) {
			dispatch(middleware.handleGetUnavailable(posId, navigate, handleClearChangedUnavailable, redirectToAdjust));
		},

		clear() {
			dispatch(middleware.handleClearUnavailable(handleClearChangedUnavailable));
		},

		update(products: BusinessUnitProps[]) {
			dispatch(middleware.handleUpdateUnavailable(products));
		},

		change(product: ProductUnavailableProps) {
			if (!product.distributor) return;

			const prevState = basketStore.changedUnavailable || [];

			const changed = () => {
				if (prevState.some((p) => p.productId === product.productId)) {
					return prevState.map((p) => (p.productId === product.productId ? product : p));
				}

				return [...prevState, product];
			};

			dispatch(middleware.handleSetChangedUnavailable(changed()));
		},

		async remove(productId: string) {
			try {
				await handleRemoveFromBasket(productId);

				const prevState = basketStore.changedUnavailable || [];

				dispatch(
					middleware.handleSetChangedUnavailable(prevState.filter((product) => product.productId !== productId)),
				);
				dispatch(middleware.handleRemoveUnavailable(productId, basketStore.unavailable));
			} catch {
				console.error('Error on unavailableHandler.remove() inside basket store');
			}
		},
	};

	const handleGetBasketData = (params: Pick<HandleGetBasketDataParams, 'callGTM' | 'loading' | 'initializeBefore'>) => {
		dispatch(middleware.handleGetBasketData({ posId, isEndBasketPage, unavailableHandler, ...params }));
	};

	const basketCheckerHandler = {
		raw: basketStore.checkedBaskets,

		isChecked(basketId: string) {
			return !!basketStore.checkedBaskets?.[basketId];
		},

		replace(baskets: Record<string, boolean>) {
			dispatch(middleware.handleBasketCheckedState(baskets))
		},

		toggleCheck(basketId: string, checked: boolean) {
			dispatch(middleware.handleBasketCheckedState({ ...basketStore.checkedBaskets, [basketId]: checked }))
		},

		checkingAllInto(checked: boolean) {
			dispatch(middleware.handleBasketCheckedState(Object.entries(basketStore.checkedBaskets).reduce((acc, [id]) => ({ ...acc, [id]: checked }), {})));

		},

		async handleClearSelectedBaskets(all = false) {
			try {
				const basketsSelected = () => {
					if (all) return basketStore.basket?.baskets.map(({ id }) => id) || [];
					return Object.entries(basketStore.checkedBaskets).filter(([, checked]) => checked).map(([basketId]) => basketId);
				}

				await clearSelectedBaskets(
					posId,
					basketsSelected(),
				);

				handleGetBasketData({ loading: 'refetch' });

				const allProducts = basketStore.basket?.baskets?.flatMap((basket) =>
					basket.products.map((product) => ({
						id: product.id,
						ean13: product.ean13 || '',
						description: product.description,
						price: product.finalTotalPrice || 0,
						quantity: product.quantity || 0,
						brandName: basket.businessUnitName,
						affiliation: basket.distributorName,
						discount: Number((product.totalPrice - product.finalTotalPrice).toFixed(2)) || 0,
						productType: product.productType,
					})),
				);

				updateCartGTM('remove_from_cart', basketStore.basket?.totalPricePromotion || 0, allProducts || []);
			} catch (err) {
				notify.negative({ description: 'Aconteceu um problema ao limpar o carrinho dos distribuidores selecionados.' });
			}
		},

		get checkedIds() {
			return Object.entries(basketStore.checkedBaskets).reduce((acc, [basketId, checked]) => checked ? [...acc, basketId] : acc, [] as string[]);
		}
	};

	const getPromotionsInBasket = useCallback(() => {
		return (
			basketStore.basket?.baskets?.flatMap(({ products }) => products).filter((item) => item.productType === 'COMBO') ||
			[]
		);
	}, [basketStore.basket]);

	const getProductsInBasketByType = useCallback(() => {
		return {
			promotions: getPromotionsInBasket(),
			simples: basketStore.basket?.baskets?.flatMap(({ products }) => products).filter((item) => item.productType === 'SIMPLE') || []
		};
	}, [getPromotionsInBasket]);

	const getPromotionInBasket = useCallback(
		(promotionId: string) => {
			return getPromotionsInBasket?.().find(({ id }) => promotionId === id);
		},
		[getPromotionsInBasket],
	);


	return {
		basketStore,

		isOpenBasket,
		unavailableHandler,
		basketCheckerHandler,

		handleOpenBasket,
		handleGetBasketData,
		getPromotionInBasket,
		setChangedUnavailable,
		getPromotionsInBasket,
		handleRemoveFromBasket,
		getProductsInBasketByType,

		handleBasketLoading(loading: BasketReducer['loading']) {
			dispatch(middleware.handleBasketLoading(loading));
		},

		handleInitializeBasket(replacePosId?: string) {
			dispatch(middleware.handleLoadBasket(replacePosId || posId));
		},
	};
}

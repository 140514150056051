import { BasketProductFullParams } from "services/basket/interfaces";
import { formatProducts, handlePushEvent } from "../helpers";
import { CheckoutPageLoadProps, CloseOrderProps, PaymentFormChangeProps, SideBasketOpenProps } from "./interfaces";

const currency = 'BRL';

const handleParseProductList = (products: BasketProductFullParams[][], additionalItems = false) => {
	const formattedProducts = formatProducts(products, additionalItems);
	const items = Array.isArray(products) ? formattedProducts : [];

	return { items }
}

export const onPaymentFormChange = ({
	paymentFormLabel,
	paymentFormBasketsData,
	totalPricePromotion
}: PaymentFormChangeProps['params']) => {
	const event: PaymentFormChangeProps['event'] = {
		event: 'add_payment_info',
		value: totalPricePromotion.toFixed(2),
		currency,
		ecommerce: handleParseProductList(paymentFormBasketsData),
		payment_type: paymentFormLabel,
	}

	handlePushEvent(event);
};

export const onSideBasketOpen = ({
	basketProductsData,
	totalPricePromotion
}: SideBasketOpenProps['params']) => {
	const event: SideBasketOpenProps['event'] = {
		event: 'view_cart',
		value: totalPricePromotion.toFixed(2),
		currency,
		ecommerce: handleParseProductList(basketProductsData),
	};

	handlePushEvent(event);
};

export const onCheckoutPageLoad = ({
	basketProductsData,
	totalPricePromotion
}: CheckoutPageLoadProps['params']) => {
	const event: CheckoutPageLoadProps['event'] = {
		event: 'begin_checkout',
		value: totalPricePromotion.toFixed(2),
		currency,
		ecommerce: handleParseProductList(basketProductsData),
	}

	handlePushEvent(event);
};

export const onCloseOrder = ({
	posCNPJ,
	posName,
	basketGroupCode,
	distributorsName,
	totalPricePromotion,
	totalTaxSubstitution,
	closedOrderBasketsData,
}: CloseOrderProps['params']) => {
	const event: CloseOrderProps['event'] = {
		tax: totalTaxSubstitution,
		value: totalPricePromotion.toFixed(2),
		event: 'purchase',
		currency,
		ecommerce: handleParseProductList(closedOrderBasketsData),
		providers: distributorsName,
		store_cnpj: posCNPJ,
		store_name: posName,
		transaction_id: basketGroupCode,
	}

	handlePushEvent(event);
};